import * as React from "react"

const pageStyles = {
  color: "#232129",
  padding: 64,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}
const headingAccentStyles = {
  color: "#E52C23",
}
const paragraphStyles = {
  marginBottom: 48,
}

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Thanya Begum</title>
      <h1 style={headingStyles}>
        Congratulations
        <br />
        <span style={headingAccentStyles}>— you just reached my site! </span>
        <span role="img" aria-label="Party popper emojis">
          🎉
        </span>
      </h1>
      <p style={paragraphStyles}>
        But there's nothing here...yet. Stay tuned!
      </p>
    </main>
  )
}

export default IndexPage
